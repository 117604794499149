.home-app {
  margin: auto;
  text-align: center;
  width: 70%;
  text-align: center;
  max-width: 90vw;
  min-width: 90vw;

}

.searchbar {
  margin: 20;
}

.nav-link {
  padding: 0px !important;
  margin: 5px;
}

.navbar {
  display: block !important;
}

.container {
  width: 100%;
  // Rest of the styles...
}

@media screen and (max-width: 700px) {
  .home-app {
  min-width: 100% !important;
  }
}