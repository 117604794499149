.unauthenticated-app {
  margin: auto;
  text-align: center;
  width: 100%;
  
  max-width: 600px;
  padding: 0 20px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid rgb(217, 217, 214);
  border-radius: 10px;
  background-color: white;
}

.input {
  width: 100%;
  padding: 10px;
  border: 1px solid rgb(217, 217, 214);
  border-radius: 5px;
}

.button {
  width: 100%;
  height: 40px;
}

.error {
  color: red;
}

.loading > div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}




@media screen and (max-width: 500px) {
  /* Styles for iPhone and other small devices go here */
  .unauthenticated-app {
    min-width:100% !important;
    padding: 0 10px;
  }

  .button {
    height: 50px; /* larger button for better touch experience on small devices */
  }
}
