
  

  .event {
    height: 300px;
    max-width: 740px;
    min-width:714px;
    border-radius: 10px;
    background-color: rgb(238, 238, 238);
    color: rgb(0, 0, 0);
    border-width: 2px;
    border-color: rgb(0, 0, 0);
    border-style: solid;
    margin: auto;
    align-items: center;
    &:hover {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      transition: 0.3s;
    }
    .event-image {
      max-width: 100%;
      height: auto;
      // Other styles...
    }
    .event-image {
      border-width: 2px;
      border-color: rgb(24, 21, 21);
      border-style: solid;
      border-radius: 10px;
      max-width: 100%;
      min-width: 100%;
      max-width: 100%;
      height: auto;
    }
  }
  .event-container {
    width: 80%;
    height: 200px;
    margin: auto;
  
  
  
  
    .description {
      font-style: italic;
      font-size: 0.875rem;
      margin-bottom: 1rem;
    }
  
    .title {
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
  
      font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS',
        sans-serif;
      font-size: x-large;
      font-weight: 600;
    }
    .date, .location, .time, .cost {
      font-size: 1rem;
      margin-bottom: 0.25rem;
    }
      
    .description {
      font-style: italic;
      font-size: 0.875rem;
      margin-bottom: 1rem;
      font-style: oblique;
  
    }
      
  
  }
  
  
  
  .title {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: x-large;
    font-weight: 600;
  
    @media (max-width: 700px) { 
      font-size: large; 
    }
  }
  .button {
    min-width: 48px;
    min-height: 48px;
    // Other styles...
  }
  
  
  .event-image {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    transition: transform .2s;
    
    &:hover {
      transform: scale(1.02);
    }
  }
  
  
  .event {
    // rest of your styles...
    
    @media (max-width: 700px) { // Target devices with max screen width of 700px (like iPhone 5/SE)
      width: 90%; // Reduce width
      margin: 10px auto; // Center the element with margin auto
    }
  }
  
  .event-container {
    // rest of your styles...
    
    @media (max-width: 700px) {
      .title {
        font-size: 1.25rem; // Reduce font size for small screens
      }
    
      .date, .location, .time, .cost {
        font-size: 0.875rem; // Reduce font size for small screens
      }
  
      .description {
        font-size: 0.75rem; // Reduce font size for small screens
      }

    }
  }
  
  .event-image {
    // rest of your styles...
    
    @media (max-width: 700px) {
      width: 100%; // Let the image take full width of the container
    }
  }
  
  
  .event {
    // other styles...
  
    @media (max-width: 700px) {
      min-width: 350px !important;

    }

  }
  div.col {
    @media (max-width: 700px) {
padding: 0%;
    }
  }
  .event-container {
    // other styles...
  
    @media (max-width: 700px) {
      .title {
        font-size: 1.2rem;
      }
  
      .date, .location, .time, .cost {
        font-size: 0.9rem;
      }
  
      .description {
        font-size: 0.8rem;
      }
    }
  }
  
  .event-image {
    // other styles...
  
    @media (max-width: 700px) {
      max-width: 100%;
      height: auto;
    }
  }