.forgot-page {
  margin: auto;
  text-align: center;
  width: 50%;
  min-width: 700px;
  max-width: 700px;
  height: 100vh;

  .form-container {
    margin: auto;
    width: 100%;
    max-width: 500px;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }

  .input-group {
    margin-bottom: 20px;
  }

  .enter-password,
  .reset-password-title {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }

  .password-error-message {
    color: #f00;
    font-size: 12px;
  }

  button[type='submit'] {
    width: 100%;
  }
}


@media screen and (max-width: 700px) {
  .forgot-page{width: 100%;
  min-width: 100%
  }
}