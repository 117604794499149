
.joined-app {
  margin: auto;
  text-align: center;
  width: 50%;
  min-width: 25rem !important;
  .container {
    width: 100%;
    max-width: 48rem; // Changed to rem units
  }
  .row{
    width: 100%;
  }
}




.h1 {
  min-width: 40rem; // Changed to rem units
}

.event.joined {
  height: 300px;
  max-width: 100%;
  border-radius: 1rem; // Changed to rem units
  background-color:rgb(238, 238, 238);
  color: rgb(0, 0, 0);
  border-width: 2px;
  border-color: rgb(0, 0, 0);
  border-style: solid;
  margin: auto;
  align-items: center;

  .event-image {
    border-width: 2px;
    border-color: rgb(24, 21, 21);
    border-style: solid;
    border-radius: 1rem; // Changed to rem units
    max-width: 100%;
    height: 250px;
  }
}

.event-container {
  width: 80%;
  height: 200px;
  margin: auto;

  .title {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS',
      sans-serif;
    font-size: x-large;
    font-weight: 600;
  }
  .cost {
    font-size: medium;
    font-weight: 600;
  }
  .time {
    font-size: medium;
    font-weight: 600;
  }
  .description {
    font-style: oblique;
  }
}

.joined-header {
  max-width: 50rem !important; // Changed to rem units
  min-width: 47rem !important; // Changed to rem units
}



@media (max-width: 700px) { /* iPhones */
  .joined-app {
    width: 100%;
  }
  .description{
    display: none;
  }
  .joined-header {
    min-width: 25rem !important;
  }

  .container {
    max-width: 25rem;
  }
}




