/* Common base styles */
.editProfileApp {
  margin: auto;
  padding: 15px;
  max-width: 50%;
    background-color:rgb(238, 238, 238);
  text-align: center;
  box-sizing: border-box;
}

.profileSection {
    background-color:rgb(238, 238, 238);
  width: 100%;
  border-top: solid 1px #ccc;
  border-radius: 20px;
  padding: 20px;
}

.inputGroup {
  margin-bottom: 20px;
}

.greetingContainer {
  padding-bottom: 25px;
}

.greetingText, .usernameText {
  font-weight: lighter;
  display: inline;
}

.usernameText {
  font-weight: normal;
}

.profilePicture {
  display: inline;
  height: 100px;
  border-radius: 20px;
  margin-left: 15px;
}

.imageCaption {
  font-weight: lighter;
  display: inline;
}

.fileInput {
  width: 80%;
  margin: 20px auto;
}

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  // width: 20%;
  margin: auto;
}

.forgot-password {
  text-align: center;
}

.passwordChangeLink {
  color: black;
  font-size: 20px;
}

/* Responsive rules */
@media (max-width: 767px) { /* targeting devices with a max width of 767px (usually mobile devices) */
  .editProfileApp {
    width: 100%;
    max-width: 100% !important;
  }

  .profilePicture {
    height: 80px;
  }

  .fileInput {
    width: 100%;
  }
}

/* Styles for devices with screen width greater than 767px (usually tablets, laptops, desktops) */
@media (min-width: 768px) {
  .fileInput {
    width: 50%;
  }
}
