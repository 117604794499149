.app-profile {
  margin: auto;
  text-align: center;
  width: 50%;
    background-color:rgb(238, 238, 238);
}

.profileSection {
  background-color:rgb(238, 238, 238);
  width: 100%;
  padding: 2rem;
  border-top: solid;
  border-radius: 2rem;
}

.profileTitle {
  margin: 1rem;
  font-weight: bold;
}

.greeting {
  font-weight: bold;
}

.profilePictureSection {
  text-align: center;
}

.profilePictureWrapper {
  position: relative;
  display: inline-block;
}

.profilePicture {
  border-radius: 30%;
  width: 100px;
  height: 100px;
}

.preloader {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profileDetailsSection {
  padding: 1rem;
}

.profileDetail {
  margin: 1rem 0;
}

.detailTitle {
  font-weight: bold;
  display: inline-block;
  width: 30%;
}

.detailValue {
  display: inline-block;
  width: 70%;
  font-size: large;
  font-weight: 600;
}

.profileActions {
  text-align: center;
  padding: 2rem 0;
}

.loader {
  margin: auto;
  width: 10%;
  text-align: center;
}



/* Responsive CSS for iPhone X and similar devices */
@media screen and (max-width: 700px) {
  .app-profile {
    width: 100%;
    max-width: 100% !important;
  }

  .detailTitle, .detailValue {
    width: 100%;
    display: block;
  }
}
