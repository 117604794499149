.complete-app {
  margin: auto;
  text-align: center;
  width: 100%;
  min-width: 375px;
  overflow-y: auto;
}

.complete-container {
  max-width: 900px;
  width: 100%;
  min-width: 375px;
}

.complete-event {
  height: auto;
  max-width: 100%;
  min-width: 375px;
  border-radius: 10px;
  color: rgb(0, 0, 0);
  border: 1px solid rgb(0, 0, 0);
  background-color: rgb(238, 238, 238);
  align-items: center;

  .complete-event-image {
    border: 2px solid rgb(24, 21, 21);
    border-radius: 10px;
    width: 250px;
    height: auto;
  }
}

.complete-event-container {
  width: 100%;
  min-width: 200px;

  .title, .cost, .time, .description {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS',
      sans-serif;
    font-size: medium;
    font-weight: 600;
  }

  .title {
    font-size: large;
  }

  .description {
    font-style: oblique;
  }
}

.map, .profile-pic, .fullname, .paragraph {
  display: inline-block;
  max-width: 100%;
}

.profile-pic {
  border-radius: 50%;
  width: 30px;
  margin-left:30px ;
}

.fullname {
  font-size: medium;
  font-weight: 600;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.container-users {
  margin: 20px;
  width: 100%;
}

.paragraph {
  font-size: large;
  font-weight: 600;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

@media (max-width: 375px) {
  .complete-event {
    border-radius: 5px;

    .complete-event-image {
      width: 200px;
    }
  }
}

/* CSS for laptop sizes and larger */
@media (min-width: 1024px) {
  .complete-app {
    width: 60%;
  }

  .complete-container {
    width: 60%;
  }
}
